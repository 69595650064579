'use-strict';

const QuestionProtocolCalculator = require('./question-protocol');

module.exports = new QuestionProtocolCalculator((_evaluation, _gender, _age, answerScore) => {
    if (answerScore >= 27) return 33 - ((answerScore - 27) * 2.53);
    if (answerScore >= 14) return 66 - ((answerScore - 14) * 2.46);
    return 100 - (answerScore * 2.53);
}, [

    {
        order: 1,
        text: { mr: 'protocol.v1_emotional.mr_q1', ms: 'protocol.v1_emotional.ms_q1' },
        answers: [
            { id: 'A', value: 0, text: { mr: 'protocol.v1_emotional.mr_common_a1', ms: 'protocol.v1_emotional.ms_common_a1' } },
            { id: 'B', value: 1, text: { mr: 'protocol.v1_emotional.mr_common_a2', ms: 'protocol.v1_emotional.ms_common_a2' } },
            { id: 'C', value: 2, text: { mr: 'protocol.v1_emotional.mr_common_a3', ms: 'protocol.v1_emotional.ms_common_a3' } },
            { id: 'D', value: 3, text: { mr: 'protocol.v1_emotional.mr_common_a4', ms: 'protocol.v1_emotional.ms_common_a4' } },
            { id: 'E', value: 4, text: { mr: 'protocol.v1_emotional.mr_common_a5', ms: 'protocol.v1_emotional.ms_common_a5' } }
        ]
    },
    {
        order: 2,
        text: { mr: 'protocol.v1_emotional.mr_q2', ms: 'protocol.v1_emotional.ms_q2' },
        answers: [
            { id: 'A', value: 0, text: { mr: 'protocol.v1_emotional.mr_common_a1', ms: 'protocol.v1_emotional.ms_common_a1' } },
            { id: 'B', value: 1, text: { mr: 'protocol.v1_emotional.mr_common_a2', ms: 'protocol.v1_emotional.ms_common_a2' } },
            { id: 'C', value: 2, text: { mr: 'protocol.v1_emotional.mr_common_a3', ms: 'protocol.v1_emotional.ms_common_a3' } },
            { id: 'D', value: 3, text: { mr: 'protocol.v1_emotional.mr_common_a4', ms: 'protocol.v1_emotional.ms_common_a4' } },
            { id: 'E', value: 4, text: { mr: 'protocol.v1_emotional.mr_common_a5', ms: 'protocol.v1_emotional.ms_common_a5' } }
        ]
    },
    {
        order: 3,
        text: { mr: 'protocol.v1_emotional.mr_q3', ms: 'protocol.v1_emotional.ms_q3' },
        answers: [
            { id: 'A', value: 4, text: { mr: 'protocol.v1_emotional.mr_common_a1', ms: 'protocol.v1_emotional.ms_common_a1' } },
            { id: 'B', value: 3, text: { mr: 'protocol.v1_emotional.mr_common_a2', ms: 'protocol.v1_emotional.ms_common_a2' } },
            { id: 'C', value: 2, text: { mr: 'protocol.v1_emotional.mr_common_a3', ms: 'protocol.v1_emotional.ms_common_a3' } },
            { id: 'D', value: 1, text: { mr: 'protocol.v1_emotional.mr_common_a4', ms: 'protocol.v1_emotional.ms_common_a4' } },
            { id: 'E', value: 0, text: { mr: 'protocol.v1_emotional.mr_common_a5', ms: 'protocol.v1_emotional.ms_common_a5' } }
        ]
    },
    {
        order: 4,
        text: { mr: 'protocol.v1_emotional.mr_q4', ms: 'protocol.v1_emotional.ms_q4' },
        answers: [
            { id: 'A', value: 4, text: { mr: 'protocol.v1_emotional.mr_common_a1', ms: 'protocol.v1_emotional.ms_common_a1' } },
            { id: 'B', value: 3, text: { mr: 'protocol.v1_emotional.mr_common_a2', ms: 'protocol.v1_emotional.ms_common_a2' } },
            { id: 'C', value: 2, text: { mr: 'protocol.v1_emotional.mr_common_a3', ms: 'protocol.v1_emotional.ms_common_a3' } },
            { id: 'D', value: 1, text: { mr: 'protocol.v1_emotional.mr_common_a4', ms: 'protocol.v1_emotional.ms_common_a4' } },
            { id: 'E', value: 0, text: { mr: 'protocol.v1_emotional.mr_common_a5', ms: 'protocol.v1_emotional.ms_common_a5' } }
        ]
    },
    {
        order: 5,
        text: { mr: 'protocol.v1_emotional.mr_q5', ms: 'protocol.v1_emotional.ms_q5' },
        answers: [
            { id: 'A', value: 0, text: { mr: 'protocol.v1_emotional.mr_common_a1', ms: 'protocol.v1_emotional.ms_common_a1' } },
            { id: 'B', value: 1, text: { mr: 'protocol.v1_emotional.mr_common_a2', ms: 'protocol.v1_emotional.ms_common_a2' } },
            { id: 'C', value: 2, text: { mr: 'protocol.v1_emotional.mr_common_a3', ms: 'protocol.v1_emotional.ms_common_a3' } },
            { id: 'D', value: 3, text: { mr: 'protocol.v1_emotional.mr_common_a4', ms: 'protocol.v1_emotional.ms_common_a4' } },
            { id: 'E', value: 4, text: { mr: 'protocol.v1_emotional.mr_common_a5', ms: 'protocol.v1_emotional.ms_common_a5' } }
        ]
    },
    {
        order: 6,
        text: { mr: 'protocol.v1_emotional.mr_q6', ms: 'protocol.v1_emotional.ms_q6' },
        answers: [
            { id: 'A', value: 4, text: { mr: 'protocol.v1_emotional.mr_common_a1', ms: 'protocol.v1_emotional.ms_common_a1' } },
            { id: 'B', value: 3, text: { mr: 'protocol.v1_emotional.mr_common_a2', ms: 'protocol.v1_emotional.ms_common_a2' } },
            { id: 'C', value: 2, text: { mr: 'protocol.v1_emotional.mr_common_a3', ms: 'protocol.v1_emotional.ms_common_a3' } },
            { id: 'D', value: 1, text: { mr: 'protocol.v1_emotional.mr_common_a4', ms: 'protocol.v1_emotional.ms_common_a4' } },
            { id: 'E', value: 0, text: { mr: 'protocol.v1_emotional.mr_common_a5', ms: 'protocol.v1_emotional.ms_common_a5' } }
        ]
    },
    {
        order: 7,
        text: { mr: 'protocol.v1_emotional.mr_q7', ms: 'protocol.v1_emotional.ms_q7' },
        answers: [
            { id: 'A', value: 0, text: { mr: 'protocol.v1_emotional.mr_common_a1', ms: 'protocol.v1_emotional.ms_common_a1' } },
            { id: 'B', value: 1, text: { mr: 'protocol.v1_emotional.mr_common_a2', ms: 'protocol.v1_emotional.ms_common_a2' } },
            { id: 'C', value: 2, text: { mr: 'protocol.v1_emotional.mr_common_a3', ms: 'protocol.v1_emotional.ms_common_a3' } },
            { id: 'D', value: 3, text: { mr: 'protocol.v1_emotional.mr_common_a4', ms: 'protocol.v1_emotional.ms_common_a4' } },
            { id: 'E', value: 4, text: { mr: 'protocol.v1_emotional.mr_common_a5', ms: 'protocol.v1_emotional.ms_common_a5' } }
        ]
    },
    {
        order: 8,
        text: { mr: 'protocol.v1_emotional.mr_q8', ms: 'protocol.v1_emotional.ms_q8' },
        answers: [
            { id: 'A', value: 4, text: { mr: 'protocol.v1_emotional.mr_common_a1', ms: 'protocol.v1_emotional.ms_common_a1' } },
            { id: 'B', value: 3, text: { mr: 'protocol.v1_emotional.mr_common_a2', ms: 'protocol.v1_emotional.ms_common_a2' } },
            { id: 'C', value: 2, text: { mr: 'protocol.v1_emotional.mr_common_a3', ms: 'protocol.v1_emotional.ms_common_a3' } },
            { id: 'D', value: 1, text: { mr: 'protocol.v1_emotional.mr_common_a4', ms: 'protocol.v1_emotional.ms_common_a4' } },
            { id: 'E', value: 0, text: { mr: 'protocol.v1_emotional.mr_common_a5', ms: 'protocol.v1_emotional.ms_common_a5' } }
        ]
    },
    {
        order: 9,
        text: { mr: 'protocol.v1_emotional.mr_q9', ms: 'protocol.v1_emotional.ms_q9' },
        answers: [
            { id: 'A', value: 0, text: { mr: 'protocol.v1_emotional.mr_common_a1', ms: 'protocol.v1_emotional.ms_common_a1' } },
            { id: 'B', value: 1, text: { mr: 'protocol.v1_emotional.mr_common_a2', ms: 'protocol.v1_emotional.ms_common_a2' } },
            { id: 'C', value: 2, text: { mr: 'protocol.v1_emotional.mr_common_a3', ms: 'protocol.v1_emotional.ms_common_a3' } },
            { id: 'D', value: 3, text: { mr: 'protocol.v1_emotional.mr_common_a4', ms: 'protocol.v1_emotional.ms_common_a4' } },
            { id: 'E', value: 4, text: { mr: 'protocol.v1_emotional.mr_common_a5', ms: 'protocol.v1_emotional.ms_common_a5' } }
        ]
    },
    {
        order: 10,
        text: { mr: 'protocol.v1_emotional.mr_q10', ms: 'protocol.v1_emotional.ms_q10' },
        answers: [
            { id: 'A', value: 0, text: { mr: 'protocol.v1_emotional.mr_common_a1', ms: 'protocol.v1_emotional.ms_common_a1' } },
            { id: 'B', value: 1, text: { mr: 'protocol.v1_emotional.mr_common_a2', ms: 'protocol.v1_emotional.ms_common_a2' } },
            { id: 'C', value: 2, text: { mr: 'protocol.v1_emotional.mr_common_a3', ms: 'protocol.v1_emotional.ms_common_a3' } },
            { id: 'D', value: 3, text: { mr: 'protocol.v1_emotional.mr_common_a4', ms: 'protocol.v1_emotional.ms_common_a4' } },
            { id: 'E', value: 4, text: { mr: 'protocol.v1_emotional.mr_common_a5', ms: 'protocol.v1_emotional.ms_common_a5' } }
        ]
    }
]);